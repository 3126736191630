export const scrollToElm = (
  container: HTMLElement,
  elm: HTMLElement,
  duration: number
) => {
  var pos = getRelativePos(elm, container);
  scrollTo(container, pos.top - 50, duration); // duration in seconds
};

interface Position {
  top: number;
  right: number;
  left: number;
  bottom: number;
}

function getRelativePos(elm: HTMLElement, parent: HTMLElement) {
  // let parent = (elm.parentNode as HTMLElement)
  let pPos = parent.getBoundingClientRect();
  let cPos = elm.getBoundingClientRect();

  let pos: Position = {
    top: cPos.top - pPos.top + parent.scrollTop,
    right: cPos.right - pPos.right,
    bottom: cPos.bottom - pPos.bottom,
    left: cPos.left - pPos.left,
  };

  return pos;
}

function scrollTo(
  element: HTMLElement,
  to: number,
  duration: number,
  onDone?: () => void
) {
  var start = element.scrollTop,
    change = to - start,
    startTime = performance.now(),
    val,
    now,
    elapsed,
    t;

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = elapsed / duration;

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1) window.requestAnimationFrame(animateScroll);
    else onDone && onDone();
  }

  animateScroll();
}

function easeInOutQuad(t: number) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}
