import React, { useEffect, useState } from "react";

import "./App.scss";

import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Toast,
} from "react-bootstrap";
import Vimeo from "@u-wave/react-vimeo";
import { scrollToElm } from "./scrollHelper";
import conatusImg from "./img/conatus.png";
import syntagmaImg from "./img/syntagma.png";
import aetherImg from "./img/aether.png";
import spatiotemporalmg from "./img/spatiotemporal.png";
import cetilmg from "./img/cetilab.png";

const ToastsShowcase: React.FC = () => {
  const [show, toggleShow] = useState(true);

  return (
    <>
      {!show && <Button onClick={() => toggleShow(true)}>Show Toast</Button>}
      {/*
    // @ts-ignore */}
      <Toast show={show} onClose={() => toggleShow(false)}>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
      </Toast>
    </>
  );
};

const scrollToId = (id: string) => {
  console.log("scrollToId", id);
  const parent = document.getElementById(`parent-container`);
  const subsection = document.getElementById(id);
  if (parent && subsection) {
    scrollToElm(parent, subsection, 1);
  }
};

function App() {
  const activeContent = window.location.pathname.split("#")[1];

  useEffect(() => {
    console.log("USEEFFECT ", activeContent);
    if (activeContent) {
      scrollToId(activeContent);
    }
  }, [activeContent]);

  return (
    <div className="App">
      <header>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home">Luis Mejía</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Portfolio" id="collapsible-nav-dropdown">
                  <NavDropdown.Item
                    href="#blanca"
                    onClick={() => scrollToId("blanca")}
                  >
                    Blanca
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#archipielago"
                    onClick={() => scrollToId("archipielago")}
                  >
                    Archipiélago
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#conatus">Conatus</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#syntagma">Syntagma</NavDropdown.Item>
                  <NavDropdown.Item href="#aether">Æther</NavDropdown.Item>
                  <NavDropdown.Item href="#spatiotemporal">
                    I_I Spatiotemporal
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#ceti">
                    CETI LAB Project
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="#features">CV</Nav.Link>
                <Nav.Link
                  href="#contact"
                  onClick={() =>
                    (window.location.href = "mailto:info@luismejia.net")
                  }
                >
                  Contact
                </Nav.Link>
              </Nav>
              {/* <Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                  Dank memes
                </Nav.Link>
              </Nav> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <Container className="p-3  " id="parent-container">
        <Container className="p-5 mb-4  rounded-3 " id="blanca">
          <Row>
            <Col xs="12">
              <Vimeo video="1011572261" responsive />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>Blanca</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>
                  Spielfilm Link
                  <br />
                  (Drehbuchentwicklung und Vorproduktion)
                  <br />
                  90 Minuten
                </p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <h5>Logline</h5>
                <p>
                  Eine junge französische Modelagentin, die in den
                  Menschenhandel verwickelt ist, flieht nach Mexiko, um einem
                  gefährlichen, kriminellen Netzwerk zu entkommen. Mit nur einer
                  Waffe und dem Namen eines abgelegenen Dorfes begibt sie sich
                  auf eine gefährliche Reise durch Guanajuato, übernimmt neue
                  Identitäten und konfrontiert sich auf dem Weg mit ihrer
                  Vergangenheit.
                </p>
                <h5>Storyline</h5>
                <p>
                  Die verwegene Pariser Modelagentin Blanca (30) landet am
                  Flughafen von Mexiko-Stadt. Sie musste aus Europa fliehen, als
                  ein gefährlicher Menschenhändlerring sie mit dem Tod bedrohte.
                  Von einem wohlgesinnten, mexikanischen Kontaktmann erhält sie
                  einen Revolver, zusammen mit dem Namen eines Dorfes - San
                  Jacinto - das auf keiner Landkarte zu finden ist. Sie stiehlt
                  einen Geländewagen und macht sich in den vermeintlich
                  abgelegenen Gegenden von Guanajuato auf die Suche nach dem
                  verborgenen Dorf. Dabei treibt sie ein gefährliches Spiel
                  ihrer Identität, ändert immer wieder ihren Namen und ihr
                  Aussehen.
                </p>
                <p>
                  Rastlos und in ihren Träumen verfolgt, lässt die Fremde sich
                  nicht davon abhalten, voller Beses- senheit auf ihrer
                  unbestimmten Route zu bleiben, die sie durch fremde
                  Landschaften und Städte führt. Nach vielen Irrwegen,
                  Begegnungen mit der ländlichen Bevölkerung und einem
                  bedrohlichen Überfall gibt Blanca die Suche nach dem
                  geheimnisvollen Dorf auf und offenbart ihre Vergangen- heit in
                  einer Sprachnachricht.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="archipielago">
          <Row>
            <Col xs="12">
              <Vimeo video="1011910891" responsive />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>Archipiélago</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>
                  Featurette
                  <br />
                  in Postproduktion, Premiere 2025
                </p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <h5>Logline</h5>
                <p>
                  Erschöpft davon, ewig zu sein, erhält die Zeit den Körper
                  einer jungen Frau, um zu versuchen, auf einer Insel zu
                  verschwinden.
                </p>
                <h5>Handlung</h5>
                <p>
                  In Form einer zeitgenössischen Fabel erzählt ein allwissender
                  Erzähler die Geschichte der Zeit, dieversucht, sich auf einer
                  Vulkaninsel aufzulösen. Ermüdet davon, alles zu beobachten,
                  ist der einzige Wunsch der Zeit seitdem, sterblich zu werden.
                  Um Selbstmord zu begehen, wird der Zeit einen jungen
                  weiblichen Körper gegeben. Im Prozess des Bewusstwerdens sieht
                  sich die Zeit sowohl den Grenzen einer fast unbewohnbaren
                  Landschaft als auch denen des physischen Körpers gegenüber.
                  Durch philosophische und poetische Überlegungen beschreibt der
                  allwissende Erzähler, wie die Zeit in diesem Corpus, mit der
                  conditio humana konfrontiert wird und wie mit der Last ihrer
                  intrinsischen ewigen Natur zu kämpfen hat.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="conatus">
          <Row>
            <Col lg="12">
              <img style={{ width: "100%" }} src={conatusImg} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>Conatus</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>
                  Spielfilm
                  <br />
                  100 Minuten
                  <br />
                </p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <h5>Logline</h5>
                <p>
                  Ein gescheiterter Mittelalter-Autor begibt sich auf eine Reise
                  durch verschiedene Naturland- schaften Mexikos auf der Suche
                  nach Frieden und beruflicher Wiedergutmachung, doch ein
                  Vorfall auf der Fahrt und das Wiederaufleben traumatischer
                  Visionen aus einer – nicht so fernen – Vergangenheitführen zu
                  tiefen existenziellen Fragen.
                </p>
                <h5>Handlung</h5>
                <p>
                  In einer Zeit, die nicht allzu weit von unserer eigenen
                  entfernt ist, versucht José Alberto Scamino, ein talentierter
                  49-jähriger mexikanischer Schriftsteller, trotz eines ersten
                  Misserfolgs sein Glück alsRegisseur zu versuchen. Mit dem
                  Ziel, seinen nächsten Spielfilm vorzubereiten, begibt sich
                  Scamino auf eine Reise durch verschiedene natürliche
                  Landschaften, um künstlerische Inspiration und Ruhe zu finden.
                  Scamino wird zum Geisel seines internen niederschmettern
                  Dialogs. Während dieser einsamen Reise wird er mit großen
                  Zweifeln und Gespenstern aus seiner Vergangenheit
                  konfrontiert, die zusammen mit den Widrigkeiten der Reise und
                  der Enthüllung eines Geheimnisses sowohl seine persönliche
                  Stabilität als auch die seiner Familie gefährden.
                  Unwiderrufliche Ereignisse führen zu seinem Verschwinden.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="syntagma">
          <Row>
            <Col lg="12">
              <img style={{ width: "100%" }} src={syntagmaImg} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>Syntagma</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>3D Analogue Multimedia Concert</p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <p>
                  Syntagma bedeutet ein Zusammengesetztes, bestehend aus
                  Widersprüchen, ein Konglomerat. In sprachlichen Begriffen ist
                  es die Abfolge von Wörtern in einer bestimmten syntaktischen
                  Beziehung zueinander; eine Konstruktion. Dieses Werk verwendet
                  Musik als Analogie zur Sprache im weitesten Sinne. Beide,
                  aufgrund ihres linearen Charakters, erzeugen eine Verkettung
                  ver- schiedener Einzelelemente, die unterschiedliche
                  Musikstile wie Elektronische Musik, Experimen- telle Musik,
                  Klassische Musik und Jazz repräsentieren, unter anderem, und
                  schließlich in einer gut gesetzten und konkreten Instanz
                  münden.
                </p>

                <p>
                  Dies wurde in den letzten Monaten bereits erfolgreich in
                  verschiedenen Museen, Galerien und Festivals in verschiedenen
                  Ländern aufgeführt. Die bemerkenswertesten Veranstaltungsorte
                  wären: die dOCUMENTA 13, das Centre Pompidou in Paris, die
                  Hamburger Kunsthalle, die Fonoteca Nacional, das Nationale
                  Kunstzentrum und das MUAC in Mexiko, die Contemporary Art Ruhr
                  Kunstmesse auf dem Welterbe Zollverein in Essen, das
                  MUTEK-Festival und das Mapping-Festival in Genf, unter
                  anderem.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="aether">
          <Row>
            <Col lg="12">
              <img style={{ width: "100%" }} src={aetherImg} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>Æther</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>Symphonie</p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <p>
                  Von der innigen Parität der Schatten bis zur offenbarten
                  Klarheit, steigt das allwissende Hell-Dunkel zur Materie auf
                  und sublimiert sich zu Luft. Gas, es transzendiert. Gas, es
                  kondensiert, Alchemie formt es zu Plasma. Dieses unendliche
                  und irdische Amalgam beherbergt die ursprüngliche Triade aus
                  Geist, Weisheit und Kunst. Heilige Trommeln des Mysteriums
                  grüßen eine bessere Welt, indem sie den Stein der Weisen
                  feiern, im Wissen, dass die Wahrheit uns frei machen wird.
                </p>

                <p>
                  Kommissionierte Komposition zum Eröffnungskonzert der
                  International Image Festival
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="spatiotemporal">
          <Row>
            <Col lg="12">
              <img style={{ width: "100%" }} src={spatiotemporalmg} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>I_I Spatiotemporal</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>Performance</p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <p>
                  Eine wissenschaftliche Definition, die durch die Erforschung
                  der komplexen Konzepte von Raum und Zeit in eine Performance
                  eingebracht wird. Mit dem Ziel, in den gegebenen räumlichen
                  Kontext einen Raum mit unabhängiger Zeit zu schaffen,
                  behandelt I_I Spatiotemporal die sozialen Erscheinungen von
                  Angst, Freiheit und Unterdrückung. Zwischen Vergnügen und Leid
                  erfolgt eine Untersuchung der Ästhetik des Horrors, die das
                  Publikum auf eine Reise durch ein gemein- sames Imaginäres
                  voller Fragen undI_I Spatiotemporal Ängste unserer Zeit
                  mitnimmt.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="p-5 mb-4  rounded-3 " id="ceti">
          <Row>
            <Col lg="12">
              <img style={{ width: "100%" }} src={cetilmg} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs="3">
              <div style={{ textAlign: "left" }}>
                <h5>CETI LAB Project</h5>
              </div>
            </Col>
            <Col xs="9">
              <div style={{ textAlign: "left" }}>
                <p>Ortsspezifische installation</p>
              </div>
            </Col>
            <Col xs="12">
              <div style={{ textAlign: "left" }}>
                <p>
                  The unaccountable for the non-observer ist eine
                  ortsspezifische Installation, die das Prinzip der akustischen
                  Rückkopplung und Schallresonanz an der Antenne des
                  Radioteleskops in Byurakan, Armenien, erforscht. Sie
                  untersucht die wechselseitige Natur der Kommunikation, indem
                  sie einen akustischen Spiegel der Umgebung schafft. Wo das
                  radiooptische Teleskop ursprünglich dazu gedacht war,
                  Funksignale aus dem Weltraum aufzuzeichnen, kehrt Mejía die
                  Struktur um, um eine sich ständig verändernde Klanglandschaft
                  der Erde zu verstärken, die sowohl durch den Teilnehmer
                  definiert als auch wahrgenommen wird. Die Wahrnehmung von Raum
                  und Zeit des Publikums wird verstärkt, da vergangene und
                  gegenwärtige Klänge zu einem Ganzen verwoben werden.
                </p>
                <p>
                  Die resultierenden Frequenzen hängen von der Tiefe, dem Radius
                  und der geographischen Lage der Schüssel ab. Omnidirektionale
                  Mikrofone werden entlang der drei Beine der Antenne platziert
                  und auf die akustischen Schnittpunkte der Parabel
                  ausgerichtet, eines hängt in Richtung des zentralen
                  Resonanzpunkts. Dennoch bleibt das eigentliche Ziel der Anlage
                  auf die großartige Schönheit ihres metallischen Korpus
                  beschränkt.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default App;
